import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import BasicTable from "components/basicTable";
import Youtube from "components/youtube";
import AccentBox from "components/accentBox";
import { graphql } from "gatsby";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "storage/sshd"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/sshd.png"}) { ...eyecatchImg },
    sshd_sikumi: file(relativePath: { eq: "storage/sshd_sikumi.png"}) { ...normalImg },
    optane: file(relativePath: { eq: "storage/optane.PNG"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`SSHDとは？SSDをキャッシュとして用いたHDD製品で高速化を実現`}</h1>
    <time itemProp="datePublished" dateTime="Sun Dec 13 2020 20:07:28 GMT+0900 (GMT+09:00)">更新日:2020年12月13日</time>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="SSHD" mdxType="Image" />
    <p><strong parentName="p">{`大量安価のHDDに少量高価なSSDを搭載することで、SSDに近い速度とHDDに近いコストを両立した製品がSSHD`}</strong>{`である。`}</p>
    <p>{`SSHDは`}<strong parentName="p">{`Solid State Hybrid Drive`}</strong>{`の略でSSD(Solid State Drive)にHDDを組み合わせたという意味である。`}</p>
    <p>{`こちらはSSDが主体の名前であるが、HDDを主体としてHybrid HDDと呼ばれることもある**。`}</p>
    <p>{`このページではSSHDの成り立ち、特性の説明から実際の速度がどれだけ向上するかについてまず最初に述べる。そして現在SSHDはほとんど販売されなくなっているため、SSHDに代わるOptaneメモリー等について解説する。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "SSHDとは",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#SSHD%E3%81%A8%E3%81%AF",
        "aria-label": "SSHDとは permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SSHDとは?`}</h2>
    <p>{`HDDは安価であるが遅く、SSDは高価であるが速い特徴がある。そこで最も頻繁にアクセスされるデータのみをSSD側に格納し、頻繁にはアクセスされないデータをHDDに格納すれば、`}<strong parentName="p">{`少量のSSDであっても全体の高速化を図ることができる`}</strong>{`。`}</p>
    <p>{`このようなアイデアから生まれた商品がSSHDで、仕組みを図示すると下記のようになる。`}</p>
    <Image {...props} name="sshd_sikumi" alt="SSHDの仕組み" mdxType="Image" />
    <p>{`たとえば、Storage容量が1TBあり、SSDが全体の1%である10GBであったとしても、OSから頻繁にアクセスされるデータを格納しておくことにより、`}<strong parentName="p">{`80%程度のデータはSSDからのアクセスとする事が十分に可能`}</strong>{`となる。`}</p>
    <p>{`SSDに格納されるべきデータはストレージに内蔵されたキャッシュコントローラにより制御される。使用者が頻繁に使うデータへとSSDの内容物を切り替えていくことによりSSDのヒット率を向上させる仕組みを備えている。`}</p>
    <p>{`速度としてはSSDとHDDの中間程度(後述)であるが、SSDの価格下落などにより2015年頃から徐々に見かけなくなり、`}<em parentName="p">{`2020年にはほぼ消滅`}</em>{`している。今は中古や並行輸入品で入手できる程度である。`}</p>
    <h3 {...{
      "id": "発想はキャッシュ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E7%99%BA%E6%83%B3%E3%81%AF%E3%82%AD%E3%83%A3%E3%83%83%E3%82%B7%E3%83%A5",
        "aria-label": "発想はキャッシュ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`発想はキャッシュ`}</h3>
    <p>{`SSHDの仕組みはキャッシュという考え方に基づく。キャッシュは何度もアクセスされるデータを高速に読みこむ仕組みである。`}</p>
    <p>{`たとえば、Webサイトへ2度目のアクセスをする際は速いと感じる時がある。これはインターネット経由で取得したデータをパソコンのストレージに保存しており、次回以降はストレージからのアクセスとなるためである。`}</p>
    <p>{`CPUも同様に、計算に頻繁に使うデータを内部の高速な一次キャッシュに抱え、そこでヒットしないものは二次キャッシュへと、多重でキャッシュを抱えて高速化を実現している。`}</p>
    <p>{`同様の考えで`}<strong parentName="p"><em parentName="strong">{`SSHDはSSDをHDDのキャッシュとして用いることで高速化している`}</em></strong>{`のである。`}</p>
    <h3 {...{
      "id": "初回起動時の速度は変わらず",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%88%9D%E5%9B%9E%E8%B5%B7%E5%8B%95%E6%99%82%E3%81%AE%E9%80%9F%E5%BA%A6%E3%81%AF%E5%A4%89%E3%82%8F%E3%82%89%E3%81%9A",
        "aria-label": "初回起動時の速度は変わらず permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`初回起動時の速度は変わらず`}</h3>
    <p>{`キャッシュの仕組みを用いているため`}<strong parentName="p">{`SSHDのパソコンを初回起動したときは速度が変わらない`}</strong>{`。まだ何もSSDに頻繁に使うデータが割り当てられていないためである。`}</p>
    <p>{`しかし暫く使用しているうちに使用頻度の高いデータを自動で学習してくれるため、２回目の起動時、あるいは同一のアプリを暫く使用しているうちに速度がアップしていく。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`使用頻度の高い機能ほど速度が上がる`}</em></strong>{`ことが肝であり、たまにしか使わないソフトを立ち上げる場合などはその恩恵を受けられないであろう。`}</p>
    <h3 {...{
      "id": "SSHDは読み込みが速いが書き込みは遅い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#SSHD%E3%81%AF%E8%AA%AD%E3%81%BF%E8%BE%BC%E3%81%BF%E3%81%8C%E9%80%9F%E3%81%84%E3%81%8C%E6%9B%B8%E3%81%8D%E8%BE%BC%E3%81%BF%E3%81%AF%E9%81%85%E3%81%84",
        "aria-label": "SSHDは読み込みが速いが書き込みは遅い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SSHDは読み込みが速いが書き込みは遅い`}</h3>
    <p>{`SSHDは読み込みを速くする技術であり、書き込みを速くするためのテクノロジーも備わっているものの読み込みと比べたら効力は少ない。よって`}<strong parentName="p">{`ファイルのコピーなど書き込み速度が重視される項目では恩恵が得られない`}</strong>{`。`}</p>
    <p>{`この一つの原因として、SSDは書き込み回数が決まっているということである。数ギガバイト程度のSSDにアプリやOSを起動する度に書き込みが行われているとすぐに寿命を迎えてしまう。`}</p>
    <p>{`そこで書き込み時にはHDDに直接書き込むため速度は通常のHDDとほぼ変わらない。`}</p>
    <h3 {...{
      "id": "8GB程度のSSHDが主流",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#8GB%E7%A8%8B%E5%BA%A6%E3%81%AESSHD%E3%81%8C%E4%B8%BB%E6%B5%81",
        "aria-label": "8GB程度のSSHDが主流 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`8GB程度のSSHDが主流`}</h3>
    <p>{`コストとの兼ね合いからかSSDを8GB程度搭載したSSHDが多い。SSDとHDDの総データ読み込み量に占めるSSDの割合をヒット率と呼ぶが、8GBでのヒット率と16GBでのヒット率がそう変わらないため、コストパフォーマンスを考えそれぐらいの容量になっているのであろう。`}</p>
    <h3 {...{
      "id": "SSHDのメリット・デメリットは？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#SSHD%E3%81%AE%E3%83%A1%E3%83%AA%E3%83%83%E3%83%88%E3%83%BB%E3%83%87%E3%83%A1%E3%83%AA%E3%83%83%E3%83%88%E3%81%AF%EF%BC%9F",
        "aria-label": "SSHDのメリット・デメリットは？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SSHDのメリット・デメリットは？`}</h3>
    <p>{`まとめる必要もなくシンプルであるが最後にメリット・デメリットを整理しておく。`}</p>
    <AccentBox title="SSHDのメリット" type="check" mdxType="AccentBox">
  <li>HDDより高速</li>
  <li>SSDより安価</li>
  <li>ヒット率次第ではSSDと同等速度</li>
    </AccentBox>
    <AccentBox title="SSHDのデメリット" type="check" mdxType="AccentBox">
  <li className="bad">SSDより低速</li>
  <li className="bad">HDDより高価</li>
  <li className="bad">ヒット率次第ではHDDと同等速度</li>
  <li className="bad">HDDと同等の重量</li>
    </AccentBox>
    <h2 {...{
      "id": "SSD-vs-SSHD-vs-HDDの実際の速度",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#SSD-vs-SSHD-vs-HDD%E3%81%AE%E5%AE%9F%E9%9A%9B%E3%81%AE%E9%80%9F%E5%BA%A6",
        "aria-label": "SSD vs SSHD vs HDDの実際の速度 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SSD vs SSHD vs HDDの実際の速度`}</h2>
    <p>{`Youtubeに実際の速度がどの程度か比較した動画が上がっているため紹介しよう。`}</p>
    <Youtube mdxType="Youtube">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/6iBhf8rpobo" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </Youtube>
    <p>{`この動画では標準的なSata接続のSSD、8GBのSSDを搭載したSSHD、7200RPM(Rotaition per minute:回転/分)の高速HDDで比較を行っている。その結果は以下の通りであった。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <th></th>
    <th>OSの起動</th>
    <th>OSの再起動</th>
    <th>Chromeブラウザの起動</th>
  </thead>
  <tbody>
    <tr>
      <td>SSD</td>
      <td>18秒</td>
      <td>22秒</td>
      <td>4秒</td>
    </tr>
    <tr>
      <td>SSHD</td>
      <td>32秒</td>
      <td>27秒</td>
      <td>5秒</td>
    </tr>
    <tr>
      <td>HDD</td>
      <td>46秒</td>
      <td>70秒</td>
      <td>7秒</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`使用条件については詳しく述べられてはいなものの、SSHDは`}<strong parentName="p">{`HDDとSSDのほぼ中間のパフォーマンス`}</strong>{`を出している事が分かる。`}</p>
    <p>{`OSの再起動においてはSSDに迫る性能を見せているが、OSを起動したばかりのため、起動のために必要なデータがSSDに残り、再起動時により高速になったものだと予想でき、キャッシュを使う特性からも理解できる。`}</p>
    <h2 {...{
      "id": "HDDの速度ではやや不満という人におすすめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#HDD%E3%81%AE%E9%80%9F%E5%BA%A6%E3%81%A7%E3%81%AF%E3%82%84%E3%82%84%E4%B8%8D%E6%BA%80%E3%81%A8%E3%81%84%E3%81%86%E4%BA%BA%E3%81%AB%E3%81%8A%E3%81%99%E3%81%99%E3%82%81",
        "aria-label": "HDDの速度ではやや不満という人におすすめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HDDの速度ではやや不満という人におすすめ`}</h2>
    <p>{`SSHDのコストはHDDと比べると3割増しといった具合であるが、SSDと比べると遥かに安いコストで大容量が実現できる。`}</p>
    <p>{`おすすめしたいユーザーとしては、今までのHDDで特に不満はないが、色を付けて少し速くしたいユーザーである。`}</p>
    <p>{`ただ、既に述べたようにSSHDの製品は現在ほとんど販売されていない。次の節では代替となる手段を紹介する。`}</p>
    <h2 {...{
      "id": "SSHD以外の選択肢はSSDとの組み合わせかOptaneメモリー",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#SSHD%E4%BB%A5%E5%A4%96%E3%81%AE%E9%81%B8%E6%8A%9E%E8%82%A2%E3%81%AFSSD%E3%81%A8%E3%81%AE%E7%B5%84%E3%81%BF%E5%90%88%E3%82%8F%E3%81%9B%E3%81%8BOptane%E3%83%A1%E3%83%A2%E3%83%AA%E3%83%BC",
        "aria-label": "SSHD以外の選択肢はSSDとの組み合わせかOptaneメモリー permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SSHD以外の選択肢はSSDとの組み合わせかOptaneメモリー`}</h2>
    <p>{`SSHDの代替手段として検討すべきは、SSDとHDDをデータの特性によって使い分けるか、SSHDと基本的な考え方を同じくするOptaneメモリーを導入する方法である。それぞれ説明する。`}</p>
    <h3 {...{
      "id": "SSDとHDDの両方をパソコンに搭載する",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#SSD%E3%81%A8HDD%E3%81%AE%E4%B8%A1%E6%96%B9%E3%82%92%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AB%E6%90%AD%E8%BC%89%E3%81%99%E3%82%8B",
        "aria-label": "SSDとHDDの両方をパソコンに搭載する permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SSDとHDDの両方をパソコンに搭載する`}</h3>
    <p>{`まずは256GB程度の少量のSSDと大容量のHDDを両方一つのパソコンに搭載する案である。`}</p>
    <p>{`OSをSSDにインストールしておけば常に高速で動作し、使用頻度の低いファイルや、容量の大きい動画ファイルをHDDに移すことで容量も確保できる。`}</p>
    <p>{`販売されているパソコンはこの形態で組まれているものが多く、自分でSSDとHDDを購入して組み立てる必要もない。`}</p>
    <p>{`欠点としては定期的にファイルをSSDからHDDへ移す必要があり、 `}<strong parentName="p">{`何をSSDに残し、何をHDDに移すかで迷う`}</strong>{`ことがある。`}</p>
    <p>{`このゲームはファイル容量は大きいけれども頻繁にアクセスするからSSDの領域においておこう、とか、SSDの容量が足りなくなってきて大きなサイズのアプリケーションがインストールできないのだがどうしようか？などと迷うことになる。`}</p>
    <h3 {...{
      "id": "OptaneメモリーとHDDの組み合わせ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Optane%E3%83%A1%E3%83%A2%E3%83%AA%E3%83%BC%E3%81%A8HDD%E3%81%AE%E7%B5%84%E3%81%BF%E5%90%88%E3%82%8F%E3%81%9B",
        "aria-label": "OptaneメモリーとHDDの組み合わせ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OptaneメモリーとHDDの組み合わせ`}</h3>
    <Image {...props} name="optane" caption="Optaneメモリー" mdxType="Image" />
    <p>{`次に`}<a parentName="p" {...{
        "href": "https://amzn.to/2YrXRnU",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`Optaneメモリー`}</a>{`をHDDのキャッシュとして使うという方法がある。`}</p>
    <p>{`Optaneメモリーはインテルとマイクロンという会社により共同で開発され、少量で高価であるがSSDよりも高速という特徴を持つ。`}</p>
    <p>{`SSHDではHDDのキャッシュとしてSSDを用いていたが、`}<strong parentName="p">{`OptaneをHDDのキャッシュとして導入することで、`}<em parentName="strong">{`SSHD以上の高速性を実現できる`}</em></strong>{`というわけである。`}</p>
    <p>{`キャッシュとしての用途であるため、初回起動が遅い点はSSHDと同じであるなどの特徴も同じであり、`}<strong parentName="p">{`使い勝手の観点からは`}<em parentName="strong">{`SSHDの正当進化系`}</em>{`と言えなくもない`}</strong>{`。`}</p>
    <p><strong parentName="p">{`16GBとSSHDのSSDよりも容量が大きく、かつ高速であるため、`}<em parentName="strong">{`SSHDよりも優れていることは明らか`}</em></strong>{`であり、`}<strong parentName="p"><em parentName="strong">{`Optaneの登場がSSHDにとどめを刺した`}</em></strong>{`と言っても過言ではないだろう。`}</p>
    <p>{`ただ注意点として、M.2ソケットをサポートしていること、Windows 10以降であること、AMDのCPUが搭載されている場合は使えないなど制約は多いので自作で導入するようなケースでは注意が必要となる。`}</p>
    <h2 {...{
      "id": "SSHDまとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#SSHD%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "SSHDまとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SSHDまとめ`}</h2>
    <p>{`SSHDはSSDをキャッシュとして使うHDDであり、`}<strong parentName="p">{`性能はSSDとHDDの中間程度のパフォーマンス`}</strong>{`であり、安価で高い性能が期待できる。`}</p>
    <p>{`一方でSSDとHDDの組み合わせやOptaneメモリーをキャッシュとして使う方法などがSSHDに代わる主流となっており、`}<strong parentName="p">{`SSHDを使用する機会はほぼ無くなったと言っても良い`}</strong>{`。`}</p>
    <p>{`安価で入手できる機会があった場合はHDDより高速な手段として導入するのは無きにしも非ずといったところ。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      